import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const ImageGallery = makeShortcode("ImageGallery");
const ImageGalleryImage = makeShortcode("ImageGalleryImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Certification achieved by our organization as part of our continuous development plan.`}</p>
    </PageDescription>
    <h2>{`SolidWorks Certifications`}</h2>
    <ImageGallery mdxType="ImageGallery">
      <ImageGalleryImage alt="Mechanical Design Professional" title="Mechanical Design Professional" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "860px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADeElEQVQ4y51SSW8URxRuCSEwwgfw2MYsJ3PBLLK4IbHdAPsAQuIEWIAYNslYtqMoxtLkD+SSQ6QIlAgJbkgkyuKwBCEOjkMcByI5xsbYzHRPT09Pd8/YnqVnprreF1V1tz2ZgLKU9OmrqvfeV9973QoAxXGyB1zXjZVcd6hYcm+UXPdGKeR61MXL5fKQqM1Y1gGhpSwuLe1jngciwv9Zok6gyhhs29mncM4HAym34Faq9mKR5Utlliu4bCHAYtFlBbcikcu7rFSpyjvBAKqiVohWKpVBBUCf53EhyG7+ME49n9yjwVsjdO2zb+jDLx7Qx3d/pOHbj6j/5vf00ZcPKfrpV/TBrRGZN3T7EZm5vPDDOCcwxvoUIuoL2mXpbJ50ZwnPp1U8eTmHzEIBk/E0xmeSmIyb+H3ewMSsjlndxsQbHS/mUiLHF6RAUDgMZiHsS+WfphK48+QF7o9O4vFvs/h6bAoPfn2N+6N/YOyVKlk8tDJGYlQrGDrkJBeWimVk8yX8MqPhjW7jbTqLl3MpzKezmDMczGiWjIvF/eI6Qc5BnDPinMQeXM4UI89f4fPvfsbD8Rl8OzaFO48nMD6thr4Q1FFQuzLDwPpyy+EPlClWoOWKkqfTOYy+TmGh4qE2J9gyKRA65NksmK4zzzDIMwwIsFQKyJiAlVlhOwOYaRkL82SNqBUanucLOr290LZtY/qePaTv3Am9owOCkx0d74TMCbF7N2lbtzJnYAAe4AtaZ88ivno1UzduJHXDBqiRCNSmpn+HlhaKr1rFrAsXagTPn0di7VrpMNneDrW5GdrmzdDa2v6O+vstWyjR0MDsS5f+KhhXFGZ2d5PV0yMFg9d9twLiTrDooLV1+ay1tVFizRpmR6M1gmfOINneznKxGDn9/dB37UJueBhi71y/DrOrC7lYDPblyzCPH5dn6/Rp2FeuIHPyJKU6O2XLvFYwfeQIM0+cILO7G/qOHRBOM6dOQcTMY8d87upC+uhRGAcPwti/H8ahQzAOH6bk9u11gmKG69cztbWV1EiEtE2bxMchtanJ50hk5SzQ0kJqc/Nyrph/bcsD1rlzmFeUcqKx0UusW+clGhp8fh9EPERjo/dWUcrBRxlQGLC3+PSpa1+8CIloFMv7f0KYG42i+OyZWwD2CodKFegE0AvgKoBr/xFXCegt+RrKnxSFzaCbwcNsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/45c8f90bc44e258b71a012013d2745af/5f1c3/MechanicalDesign.webp 288w", "/static/45c8f90bc44e258b71a012013d2745af/c4eb6/MechanicalDesign.webp 576w", "/static/45c8f90bc44e258b71a012013d2745af/7464f/MechanicalDesign.webp 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/45c8f90bc44e258b71a012013d2745af/672f0/MechanicalDesign.png 288w", "/static/45c8f90bc44e258b71a012013d2745af/cbcfa/MechanicalDesign.png 576w", "/static/45c8f90bc44e258b71a012013d2745af/e0774/MechanicalDesign.png 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/45c8f90bc44e258b71a012013d2745af/e0774/MechanicalDesign.png",
              "alt": "Mechanical Design Professional",
              "title": "Mechanical Design Professional",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="Welments Professional" title="Welments Professional" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "860px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADLklEQVQ4y52RS0wTURSGJzEGMbJQCgjoCjYqGsLKha8dCAuNiSvEVyygCRCKMYqmcW/caOLCR9TEuFAXmBifMSQsjMa38YFIsJ3pMJ3OtAP2MW3vzPnNnZlirTGgTf7858695+t/zxUACAnD2GyaZjBjmifSGXM4Y5rDmYKXqmQ/m82e4L2arm/mLCGZTG60LIuICP/z431cjDGKJxIbBdumIQ9lpsxcXp9Ns2Qmx2ZS5pxm01mWNnMsZeaYkTJZJpdns2nXAeR5L4fm8/khAcCAZdkcyC4+eEn7zt6hoUv3qff8XTp+9RGdvvGUTl1/QoGL9+nYlYfUfW6Ejl5+QF1nbtPwtcekGkmeh9m2k3JAIKIB77osmkiSHP+BVxMRvPgqIawa+BiK4sW4hLeT0/gixvBmchrjkoZPYdWpYzMpF0gekCf0ZsHjO+T3U9MYefYJF+49x83Rdxh59hlP307i1tgHx68/eY0pJV40RmJUDCwktL1iJmViIqIjpBr4JutOUkmbwbgUc3wioiGaSDo0r6cEaNsg22Zk28RrL+j8L+z2kdf7a4bePism0QJUdJQ5gEJCO5EAk2VmKQpZioJ/FFm8lzMsywXG+/ogrV7N5A0bSF63DvLatXB8IVq/nqRVq1g8EIAFuECtqwuhxYuZuGIFicuXQ/T5IFZWLkzV1RRatIhpBw8WAQ8cQHjJEidhpKEBYlUVpLo6SLW1f6r0e309hcvLmd7d/TswJAhM7eggbe9eB+j9u5uWi3/jzm9QUzO3lmprKVxWxnS/vwi4Zw8iDQ3MCAYpPjgIuakJxsmT4HW8vx9qezuMYBB6Tw/UHTuctdbZCb23F7Fdu2i6udm5sl0MjLa2MnXnTlI7OiCvWQOeNLZ7N/ieun276+3tiLa1QdmyBcqmTVC2boWybRtFGhtLgHyGy5YxsaaGRJ+PpJUr+eOQWFnpus/3a81VXU1iVdXcWT7/4isHtP37MSUI2XBFhRVeutQKl5e7/jfx/YIqKqzvgpD1HiUgWEBLenQ0ox86BEd+P+bq+VQ46/cjPTaWSQMtPCGHNgPoA3AYwJF/FO/py7oM4SdZVNMV6eltrQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3a8b42dcbeed723a7e5a39367fc489f7/5f1c3/Weldments.webp 288w", "/static/3a8b42dcbeed723a7e5a39367fc489f7/c4eb6/Weldments.webp 576w", "/static/3a8b42dcbeed723a7e5a39367fc489f7/7464f/Weldments.webp 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3a8b42dcbeed723a7e5a39367fc489f7/672f0/Weldments.png 288w", "/static/3a8b42dcbeed723a7e5a39367fc489f7/cbcfa/Weldments.png 576w", "/static/3a8b42dcbeed723a7e5a39367fc489f7/e0774/Weldments.png 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3a8b42dcbeed723a7e5a39367fc489f7/e0774/Weldments.png",
              "alt": "Welments Professional",
              "title": "Welments Professional",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
      <ImageGalleryImage alt="Mold Tools Professional" title="Mold Tools Professional" col={3} mdxType="ImageGalleryImage">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "860px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADV0lEQVQ4y51RS2wTVxQdCSEIIguI8+OzChsSQBE7JH47IFmAkFhBKCBMUqQQ4SxQAvKi26qbskulqixaVW0XID4t9JMKqSooUApIgQYU4rHHY3tm4sSx/Wb8Zu5Bd2acOKYVbZ90dN/9nXfufQoAxZqd3SOEiJeFGCmVxWhZiNFy1dajLm/b9gj3Goa5h7mUQqGwy3VdEBH+z+E+hpQSpmXtUjzPGw6pRFE4FXO+JIvCkXNFsYj5ku3HFoQj80UhhSP9WNmpSAAV7mVSx6kMKwCGXNdjQjn2/QR98Ml3FBu7TQNXr9Olz3+gj778ha5c+5FiY3do9It71P/pdRr+7A71ffyt72fzC6xHep6vckghoqFwXJnJL5BmzuPZGx0PXyaRMubxaCqFib9SePxKw2+TCT/+Om3h+UwGT16nkZsrBoQUErLCcBcsf9kirUIZdx9P4eaDF7jx+ySu/fQHisL5mzWSpFrCqkLO8Euu5/nMC2Ub07oFNTeHialAJa+NwhqP64LmOkJOeJ4kz6Pw7oMFf/PrU3z18xN8Pf4nbj2YhG1XWBNq6ijsXdphKP2dkfmUXEIyX0K6IJArOf6X1o8c9i4p9GZnITVNurpOrq6jCplOgzIZwMgBuawPL4zX1JHLvczhugGhNTiI5ObNUtuxg7SuLmidnfBtVxdSnZ3LUJvzsX07JTdtklYsBhcICI2+PsysXCnV9etJXbcOaiQCtanp36GlhWZWrJDGmTM1hKdPI7F6ta8w1dEBtbkZyQ0bkGxvfxf18Y0bKdHQIM1z55YTziiKzPb2knHypE8Yvh6oZXCMLU/Q2rroJ9vbKbFqlTSj0RrCEyeQ6uiQ+XicrIsXoW3bhvzly+C7deECsj09yMfjMPv7kT182PeN48dhDgwgd/Qopbu7/ZG9WsLMgQMye+QIZXt7oW3dClaaO3YMnMseOhTYnh5kDh6Evncv9N27oe/bB33/fkpt2VJHyDtcu1aqra2kRiKUbGvjzyG1qSmwkciSz2hpIbW5ebGW9187csw4dQrTimInGhvdxJo1bqKhIbD/BM5X0djovlEUO/yUmCKBnaXxcWGePQsf0SgW7+9DtTYaRen+fVEEdrJCpQJ0AxgE8CGA8/8R3DMoAg7lLUZp067/csQBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d464e9ab63a20197289ea8f5e7efa24d/5f1c3/MoldTools.webp 288w", "/static/d464e9ab63a20197289ea8f5e7efa24d/c4eb6/MoldTools.webp 576w", "/static/d464e9ab63a20197289ea8f5e7efa24d/7464f/MoldTools.webp 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d464e9ab63a20197289ea8f5e7efa24d/672f0/MoldTools.png 288w", "/static/d464e9ab63a20197289ea8f5e7efa24d/cbcfa/MoldTools.png 576w", "/static/d464e9ab63a20197289ea8f5e7efa24d/e0774/MoldTools.png 860w"],
              "sizes": "(max-width: 860px) 100vw, 860px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d464e9ab63a20197289ea8f5e7efa24d/e0774/MoldTools.png",
              "alt": "Mold Tools Professional",
              "title": "Mold Tools Professional",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </ImageGalleryImage>
    </ImageGallery>
    <p>{`Issued By: `}<strong parentName="p">{`Dassault Systèmes SOLIDWORKS Corp | Design & Development AB`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      